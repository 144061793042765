export const environment = {
    production: false,
    supabaseUrl: 'https://jhbmgygpxlrrthhgwekc.supabase.co',
    supabaseKey: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImpoYm1neWdweGxycnRoaGd3ZWtjIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzMyNTUwOTYsImV4cCI6MjA0ODgzMTA5Nn0.9dIbkyvUvfZ9CRRmQHFGdvzY1D-usjEOtMerNoEeoq0',
    passwordProtection: false,
    loginPassword: 'mosaic-beta',
    stripeKey: "pk_test_51O7lEzCreZRsfGkIosnIBx6P0Ki8CsJgk3lrWlHbNPfSbCZi436cskPajchwU6EPAX96bE1bME1U6kRUEQcg8ZJU00sADiEw1v",
    stripePaymentsWorker: 'https://stripe-payment-worker-beta.michael-twist.workers.dev'

  }
  